import Config from "./Config";
import Tools from "./Tools";
import IM_User from "./vo/IM_User";

export default class Global {
    public static app: any = null;
    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};
    public static main: any;

    public static login_user_vo:IM_User;

    /**
     * 显示弹出框
     * @param message 
     */
    public static AlertSuccess(message: string): void {
        Global.app?.ShowAlert(message, 1);
    }

    public static AlertError(message: string): void {
        console.error(message);

        Global.app?.ShowAlert(message, 2);
    }

    /**
     * 设置菜单
     * @param menu_arr 
     */
    public static SetMenuList(menu_arr: { name: String; href: String }[]) {
        this.main && this.main.SetMenuList(menu_arr);
    }

    /**
     * 弹出消息框
     * @param title 
     * @param content 
     * @param success_fun 
     * @param cancel_fun 
     */
    public static MessageBox(title: string, content: string, success_fun: () => void, cancel_fun: (() => void) | null = null) {
        Global.app?.MessageBox(title, content, success_fun, cancel_fun);
    }
    /**
     * 设置TOKEN
     */
    public static set SetLoginToken(v: string) {
        localStorage.setItem("login_token", v);
    }

    /**
     * 登录TOKEN
     */
    public static get GetLoginToken(): string {
        return localStorage.getItem("login_token") as string;
    }

    /**
     * 获取图标
     * @param url 
     */
    public static GetCoinIconURL(url: string) {
        if (Tools.IsNotNull(url)) {
            if (url.startsWith("data:"))
                return url;
            if (url.startsWith("image/"))
                return url;
            if (url.startsWith("/image/"))
                return url;
            if (url.startsWith("http://") || url.startsWith("https://"))
                return url;
            return `${Config.RES_URL}/${url}`;
        }
        else {
            return "";
        }
    }
}