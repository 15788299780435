/*
* 调试模式下 并不启用远程数据库节点配置表
*/
export const IS_DEBUG: boolean = false;
export default class Config {
    public static Platform_Name: string = "LookBao";
    public static WEB_API_HOST: string = IS_DEBUG ? "http://192.168.43.11:1001" : "http://mint.lookbao.net:1001";
    public static DAPP_HOST: string = IS_DEBUG ? "http://192.168.43.11:2001" : "http://mint.lookbao.net:2001";
    public static RES_HOST: string = IS_DEBUG ? "http://192.168.43.11:11111" : "http://res.lookbao.net";
    /**
     * 网站API接口地址
     */
    public static API_URL = `${Config.WEB_API_HOST}`;

    /**
     * DAPP API WEB接口地址
     */
    public static DAPP_URL = `${Config.DAPP_HOST}`;
    /**
     * 资源URL地址
     */
    public static RES_URL = `${Config.RES_HOST}`;
}