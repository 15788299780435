<template>
  <router-view/>
</template>
<script>
import Global from "@/ts/Global";
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    Global.app = this;
  },
};
</script>
<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: rgb(31, 31, 31);
  color: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.lie {
  display: flex;
  flex-direction: column;
}

.row_center {
  justify-content: center;
}

.font_size_12 {
  font-size: 12px;
}
.font_size_16 {
  font-size: 16px;
}

.lie_center {
  align-items: center;
}
.txt_left {
  text-align: left;
}

.txt_right {
  text-align: right;
}
.m_left_1 {
  margin-left: 1px;
}
.m_left_5 {
  margin-left: 5px;
}
.m_left_10 {
  margin-left: 10px;
}

.m_bottom_1 {
  margin-bottom: 1px;
}
.m_bottom_5 {
  margin-bottom: 5px;
}
.m_left_10 {
  margin-bottom: 10px;
}

.m_right_1 {
  margin-right: 1px;
}
.m_right_5 {
  margin-right: 5px;
}
.m_right_10 {
  margin-right: 10px;
}

.lie_line {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: inline-block;
  height: 100%;
  margin: 0px 10px;
}

.row_line {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: inline-block;
  width: 100%;
  margin: 10px 0px;
}


.success_btn {
  background-color: #42b983;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.delete_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.error_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.warning_btn {
  background-color: #ffb800;
  color: white;
  border: 0px;
  border-radius: 0px;
}

input{
  color: black;
}
</style>
