import router from '@/router';
import { message } from 'ant-design-vue';
import Config from './Config';
import Global from './Global';
import BackDataVO from './vo/BackDataVO';
let QRBase64Code = require("qrcode");
export default class Tools {

    /**
     * 检测是否为JSON
     * @param d 
     * @returns 
     */
    static IsJson(d: string): boolean {
        try {
            if (JSON.parse(d))
                return true;
        }
        catch (e) {

        }

        return false;
    }

    static ToJsonVO(str: string): {
        flag: boolean,
        data: any
    } {
        try {
            return {
                flag: true,
                data: JSON.parse(str)
            };
        }
        catch {
            return {
                flag: false,
                data: null
            }
        }
    }
    /**
     * 倒序排列
     * @param arr 
     * @param select_pro 
     */
    public static OrderDesc<T>(arr: Array<T>, select_pro: (x: T) => number) {
        arr.sort((a, b) => {
            return select_pro(b) - select_pro(a);
        });
    }

    /**
     * 生成GUID
     */
    public static get GUID(): string {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }

    /**
     * 获取当前时间戳
     */
    public static get GetNowTime(): number {
        return Date.now();
    }
    /**
     * 时间差转换成可读时间
     * @param difftime 时间差
     * @returns 
     */
    public static DifftimeToString(difftime: number): string {
        try {

            difftime = difftime / 1000;
            let days = parseInt((difftime / 86400).toString());
            if (difftime < 86400)
                days = 0;
            let hours = parseInt((difftime / 3600).toString()) - 24 * days;
            if (difftime < 3600)
                hours = 0;
            let minutes = parseInt((difftime % 3600 / 60).toString());
            if (minutes <= 0)
                minutes = 0;
            let seconds = parseInt((difftime % 60).toString());
            if (seconds <= 0)
                seconds = 0;

            return `${days}天,${hours}小时,${minutes}分钟,${seconds}秒`;
        }
        catch {

        }
        return "";
    }

    private static add0(m: number | string) {
        return (typeof m == "string" ? parseInt(m) : m) < 10 ? '0' + m : m
    }

    /**
     * 显示时间
     * @param time 时间
     * @returns 
     */
    public static ShowDataTime(_time: number): string {

        if (typeof _time == "string")
            _time = parseInt(_time);
        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(_time);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
        //检查是否是今天
        let now_time = new Date(this.GetNowTime);
        if (now_time.getFullYear() == y && now_time.getMonth() + 1 == m && now_time.getDate() == d) {
            show_time = `${this.add0(h)}:${this.add0(mm)}`;
        }
        else {
            show_time = `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        }
        return show_time;
    }

    /**
     * 检测是否为空
     * @param v 
     * @returns 
     */
    public static IsNull(v?: string): boolean {
        if (v == null || v == "null" || v == undefined || v == "undefined" || v == "")
            return true;
        return false;
    }
    /**
     * 是否不是空
     * @param v 
     * @returns 
     */
    public static IsNotNull(v?: string): boolean {
        return !this.IsNull(v);
    }
    /**
     * 设置本地存储
     * @param key 
     * @param v 
     */
    public static SetLocalStorage(key: string, v: string): void {
        localStorage.setItem(key, v);
    }

    /**
     * 获取本地存储
     * @param key 
     * @returns 
     */
    public static GetLocalStorage(key: string, _default: string): string {
        let v = localStorage.getItem(key);
        if (Tools.IsNull(key))
            return "";
        return v as string;
    }


    /**
     * 弹出成功消息
     * @param SUCCESS_MESSAGE 
     */
    static AlertSuccess(SUCCESS_MESSAGE: string): void {
        console.log(`${SUCCESS_MESSAGE}`);

        // Global.app && Global.app.ShowAlert(SUCCESS_MESSAGE, 1);
        message.success(SUCCESS_MESSAGE);
    }

    /**
     * 弹出失败消息
     * @param ERROR_MESSAGE 
     */
    static AlertError(ERROR_MESSAGE: string | undefined) {
        console.log(`${ERROR_MESSAGE}`);
        // Global.app && Global.app.ShowAlert(ERROR_MESSAGE, 2);
        message.error(ERROR_MESSAGE);
    }
    /**
     * 跳转
     * @param href 字符串跳转 或 数字跳转 -1代表返回
     * @param query Get传参
     * @param params Post传参
     */
    public static GoTo(href: string | number, query: any = {}, params: any = {}): void {
        if (typeof href == "string") {
            let name: string = "";
            for (let i = 0; i < router.getRoutes().length; i++) {
                const v = router.getRoutes()[i];
                if (v != null) {
                    if (v.path == href) {
                        name = v && (v.name as string);
                    }
                }
            }

            Global.params_data_dic[href] = params;
            Global.app.$router.push({
                path: href,
                query: query,
                name: name,
                params: params
            });
        }
        else if (typeof href == "number") {
            switch (href) {
                case -1:
                    Global.app.$router.back();
                    break;
                default:
                    Global.app.$router.go(href);
                    break;
            }
        }
    }

    /**
     * 获取当前的Post传参
     * @returns 
     */
    public static GetParams(): any {
        let v = router;
        let path = v.currentRoute.value.path;

        return Global.params_data_dic[path] ?? {};
    }

    /**
     * 获取当前的Get传参
     * @returns 
     */
    public static GetQuery(): any {
        return Global.app.$route.query;
    }

    /**
     * 显示LOG
     * @param tag 标签
     * @param msg 日志内容
     * @param color 颜色
     * @param font_size 字体大小
    */
    static ShowLog(tag: string, msg: any, color: string = "#0a0", font_size: number = 12): void {
        console.log(`%c【${tag}】:${msg}`, `color:${color};font-size:${font_size}px`);
    }


    /**
     * 异步上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     * @returns 
     */
    static async UploadFileAsync(action: string, data: any, files: File[]): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number
        }[]
    }> {
        // Global.ShowLoading();
        return new Promise((successfun, errorfun) => {
            try {
                this.UploadFile(action, data, files, (res: {
                    state: number,
                    msgstate: string,
                    key: string,
                    error_msg: string,
                    data: any
                }) => {
                    try {
                        Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                        successfun(res);
                    }
                    finally {
                        // Global.HideLoading();
                    }
                });
            }
            finally {

            }
        });
    }
    /**
     * 上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     */
    static UploadFile(action: string, data: any, files: File[], success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number
        }[]
    }) => void) {
        if (data == null)
            data = {};
        Tools.ShowLog(action, "上传文件", "	#1C86EE");
        data.login_token = Global.GetLoginToken;
        let url = `${Config.API_URL}?a=${action}` //API_URL + "?a=" + action;
        var xhr = new XMLHttpRequest();
        let formData = new FormData();

        for (let key in data) {
            url += `&${key}=${encodeURIComponent(data[key])}`;
        }

        for (let i = 0; i < files.length; i++) {
            const f = files[i];
            formData.append('fileUpload', f);
        }

        xhr.open("POST", url, true);
        // xhr.withCredentials = true;

        // xhr.setRequestHeader("Content-type", `multipart/form-data;`);
        // xhr.upload.onprogress = (ev) => {
        //     console.log(`进度:${Math.ceil(ev.loaded * 100 / ev.total)}%`);
        // };
        xhr.onload = function (e) {
            if (this.status == 200 || this.status == 304) {
                if (success != null)
                    success(JSON.parse(this.responseText));
            }
            else {
                Tools.AlertError("网络请求失败 错误:" + this.status);
            }
        };
        xhr.send(formData);
    }

    /**
     * 补0
     * @param num 
     * @param n 
     * @returns 
     */
    public static Pad(num: number, n: number): string {
        console.log(`要处理的数据:${num}`);
        let len = num.toString().length;
        let ret_num = num.toString();
        while (len < n) {
            ret_num = "0" + num;
            len++;
        }
        return ret_num;
    }




    /**
     * 获取资源地址
     * @param url 
     */
    static GetResourceURL(url: string) {
        return Global.GetCoinIconURL(url);
    }

    static GetBlob(canvas: any) {
        //获取blob对象
        let base64 = canvas.toDataURL("image/jpeg", 1);
        let data = base64.split(",")[1];
        data = window.atob(data);
        let ia = new Uint8Array(data.length);
        for (var i = 0; i < data.length; i++) {
            ia[i] = data.charCodeAt(i);
        }
        return {
            blob: new Blob([ia], {
                type: "image/jpeg",
            }),
            base64: base64,
        };
    }

    /**
     * 检测如果是空
     * @param msg 
     */
    static StrIsNull(msg?: string | null): boolean {
        if (msg == "" || msg == null || msg == "undefined" || msg == "null") {
            return true;
        }
        return false;
    }

    /**
     * 检测字符串不是空
     * @param msg 
     */
    static StrIsNotNull(msg?: string | null): boolean {
        return !this.StrIsNull(msg);
    }


    static RemoveLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }


    /**
     * 设置SESSION
     * @param key 
     * @param value 
     */
    static SetSessionStorage(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    /**
     * 获取SESSION值
     * @param key 
     */
    static GetSessionStorage(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    static RemoveSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
     * 清空全部session
     */
    static RemoveAllSession() {
        sessionStorage.clear();
    }
    /**
     * 检测数据包是否正确
     * @param v 
     * @returns 
     */
    public static CheckDataIsOK(v: {
        flag: boolean,
        _this: any,
        err_msg: string,
        data_vo: BackDataVO | null
    } | null, show_error_msg: boolean = true): boolean {
        if (v != null) {
            if (v.flag) {
                if (v.data_vo != null) {
                    switch (v.data_vo.STATUS) {
                        case -99: //未登录
                            Tools.GoTo("/");
                            return false;
                        case 0: //请求失败
                            if (show_error_msg)
                                Tools.AlertError(v.data_vo.ERROR_MESSAGE);
                            break;
                        case 1: //请求正常返回
                            return true;
                    }
                }

            }
        }
        return false;
    }
    /**
     * 检测数据是否正确
     * @param res 
     * @param show 是否显示 默认显示错误提示
     */
    static CheckHtmlIsOK(res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }, show_error_msg: boolean = true) {
        switch (res.state) {
            case -99:
                Tools.GoTo("/");
                Tools.AlertError("您还没有登录!");
                return false;
            case 1:
                return true;
            default:
                if (show_error_msg)
                    Tools.AlertError(res.error_msg);
                return false;
        }
    }

    /**
     * 时间戳转换成时分秒
     * @param timestamp 
     */
    static UnixTimestampToDateTime(timestamp: number): string {
        if (timestamp.toString().length == 10)
            timestamp *= 1000;
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + this.add0(D) + this.add0(h) + this.add0(m) + this.add0(s.toString());
    }


    /**
     * 获取HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtml(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void) {
        try {
            if (data == null)
                data = {};

            Tools.ShowLog(action, "HTML请求", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            let url = `${Config.API_URL}?a=${action}`;
            var xhr = new XMLHttpRequest();
            var formdata: string = "";
            for (let key in data) {
                formdata += key + "=" + encodeURIComponent(data[key]) + "&";
            }
            if (formdata != "") {
                formdata = formdata.substr(0, formdata.length - 1);
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (this.status == 200 || this.status == 304) {
                    if (success != null)
                        success(JSON.parse(this.responseText));
                }
                else {
                    Tools.AlertError("网络请求失败 错误:" + this.status);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlAsync(action: string, data: any): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {
        return new Promise((successfun, errorfun) => {
            this.GetHtml(action, data, (res) => {
                Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            });
        });
    }


    /**
 * 获取DAPP HTML
 * @param action 
 * @param data 
 * @param success 
 * @param fail 
 */
    static GetHTMLDApp(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void) {
        try {
            if (data == null)
                data = {};

            // Tools.ShowLog(action, "DAPP HTML请求", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            let url = `${Config.DAPP_URL}?a=${action}`;
            var xhr = new XMLHttpRequest();
            var formdata: string = "";
            for (let key in data) {
                formdata += key + "=" + encodeURIComponent(data[key]) + "&";
            }
            if (formdata != "") {
                formdata = formdata.substr(0, formdata.length - 1);
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (this.status == 200 || this.status == 304) {
                    if (success != null)
                        success(JSON.parse(this.responseText));
                }
                else {
                    Tools.AlertError("网络请求失败 错误:" + this.status);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHTMLDAppAsync(action: string, data: any): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {
        return new Promise((successfun, errorfun) => {
            this.GetHTMLDApp(action, data, (res) => {
                // Tools.ShowLog(`DAPP HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                // Tools.ShowLog(`DAPP HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            });
        });
    }

    /**
     * 获取QR地址
     * @param data 
     * @returns 
     */
    public static GetQRURL(data: any) {
        let url = `${Config.API_URL}/?a=getqr&data=${encodeURIComponent(
            JSON.stringify(data)
        )}`;
        return url;
    }
    /**
     * 创建BASE64二维码
     */
    static async CreateBase64QRAsync(url: string | any) {
        try {
            return await QRBase64Code.toDataURL(typeof url == "string" ? url : JSON.stringify(url));
        }
        catch (e) {
            return "";
        }
    }
}