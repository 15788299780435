import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/applet_list",
        name: "applet_list",
        components: {
          default: () => import("@/views/subviews/AppletList.vue")
        }
      },
      {
        path: "/applet_routine", //小程序常规
        name: "applet_routine",
        components: {
          default: () => import("@/views/subviews/applet_views/AppletRoutine.vue")
        }
      },
      {
        path: "/applet_live_card", //小程序常规
        name: "applet_live_card",
        components: {
          default: () => import("@/views/subviews/applet_views/AppletLiveCard.vue")
        }
      },
      {
        path: "/group_management", //小程序常规
        name: "group_management",
        components: {
          default: () => import("@/views/subviews/applet_views/GroupManagement.vue")
        }
      },
    ],

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
